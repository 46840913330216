export const LANG_LOCALES = ['en', 'pseudo', 'bn-BD', 'uk-UA', 'ja-JP'] as const

export const I18N_LANGUAGES = ['English', 'বাংলা', '日本語'] as const

export const I18N_LANGUAGE_CODES = ['EN', 'BN', 'JA'] as const

export const I18N_COUNTRIES = [
  'Global',
  'Pakistan',
  'বাংলাদেশ',
  'Bangladesh',
  '日本',
  'Japan',
] as const

export const LOCALE = {
  pseudo: {
    langLocale: 'pseudo',
    isProd: false,
    language: 'English',
    country: 'Global',
    languageCode: 'EN',
  },
  translate: {
    langLocale: 'uk-UA',
    isProd: false,
    language: 'English',
    country: 'Global',
    languageCode: 'EN',
  },
  en: {
    langLocale: 'en',
    isProd: true,
    country: 'Global',
    language: 'English',
    languageCode: 'EN',
  },
  'en-BD': {
    langLocale: 'en',
    isProd: true,
    country: 'Bangladesh',
    language: 'English',
    languageCode: 'EN',
  },
  'bn-BD': {
    langLocale: 'bn-BD',
    isProd: true,
    country: 'বাংলাদেশ',
    language: 'বাংলা',
    languageCode: 'BN',
  },
  'en-CA': {
    langLocale: 'en',
    isProd: true,
    country: 'Canada',
    language: 'English',
    languageCode: 'EN',
  },
  'en-JP': {
    langLocale: 'en',
    isProd: true,
    country: 'Japan',
    language: 'English',
    languageCode: 'EN',
  },
  'ja-JP': {
    langLocale: 'ja-JP',
    isProd: true,
    country: '日本',
    language: '日本語',
    languageCode: 'JA',
  },
  'en-NP': {
    langLocale: 'en',
    isProd: true,
    country: 'Nepal',
    language: 'English',
    languageCode: 'EN',
  },
  'en-NG': {
    langLocale: 'en',
    isProd: true,
    country: 'Nigeria',
    language: 'English',
    languageCode: 'EN',
  },
  'en-PK': {
    langLocale: 'en',
    isProd: true,
    country: 'Pakistan',
    language: 'English',
    languageCode: 'EN',
  },
  'en-US': {
    langLocale: 'en',
    isProd: true,
    country: 'United States',
    language: 'English',
    languageCode: 'EN',
  },
  'en-UG': {
    langLocale: 'en',
    isProd: true,
    country: 'Uganda',
    language: 'English',
    languageCode: 'EN',
  },
}

/** Note: copy of this contant is also present in server.js */
export const COUNTRY_WISE_LOCALES = {
  PK: ['en-PK'],
  US: ['en-US'],
  CA: ['en-CA'],
  NG: ['en-NG'],
  UG: ['en-UG'],
  NP: ['en-NP'],
  BD: ['bn-BD', 'en-BD'],
  JP: ['en-JP', 'ja-JP'],
}

// Add localized pages here which are different enough to have
// their own link as canonical link
export const LOCALE_WISE_CANONICAL_LOCALIZED_PATH_GLOBS = {
  'en-PK': [] as string[],
  'en-BD': [] as string[],
  'en-US': [] as string[],
  'en-CA': [] as string[],
  'en-NG': [] as string[],
  'en-UG': [] as string[],
  'en-NP': [] as string[],
  'bn-BD': ['/', '/login*', '/register*', '/trial-class/**', '/batches/**'],
  'en-JP': [] as string[],
  'ja-JP': ['/', '/login*', '/register*', '/trial-class/**', '/batches/**'],
}

export const USER_LOCALE_PREFERENCE_COOKIE = 'user_locale_preference'

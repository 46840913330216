import { SIGNUP_SOURCE_LOCAL_STORAGE_KEY } from '@common/constants'
import { FILE_SIZE_UNITS_IN_BYTES } from '@lib/constants/common'
import { Primitive } from '@lib/types/common'
import { setToLocalStorage } from '@lib/utils/local-storage'
import minimatch from 'minimatch'
import getConfig from 'next/config'
import Router from 'next/router'
import { parseCookies } from 'nookies'
import { captureException } from '@sentry/node'
import { getAPIErrorString } from '@lib/utils/common'

export const flattenErrorsDictValues = (errorsDict) => {
  const errorValues = Object.values(errorsDict)
  return Array.prototype.concat([], ...errorValues)
}

export const CClient = async (url: string, options: RequestInit = {}) => {
  const token = parseCookies().token
  if (!options?.headers?.['Authorization'] && token) {
    options.headers = { ...options.headers, Authorization: `Token ${token}` }
  }

  const { serverRuntimeConfig, publicRuntimeConfig } = getConfig()

  const endpoint = serverRuntimeConfig.SSR_API_ENDPOINT
    ? serverRuntimeConfig.SSR_API_ENDPOINT
    : publicRuntimeConfig.CSR_API_ENDPOINT

  const finalUrl = url.startsWith('http') ? url : endpoint + url

  return fetch(finalUrl, options)
}

type ErrorAlertProps = {
  title?: string
  text: string
  buttonText?: string
}
export const showErrorAlert = (
  props: ErrorAlertProps,
  onDismiss?: () => void
) => {
  const { title, text, buttonText } = props
  import('sweetalert').then((swal) => {
    swal
      .default({
        icon: 'error',
        title: title || 'Error!',
        buttons: {
          confirm: {
            text: buttonText ? buttonText : 'Ok',
            value: true,
          },
        },
        text,
        closeOnClickOutside: false,
        closeOnEsc: false,
      })
      .then((val) => {
        if (val && onDismiss) {
          onDismiss()
        }
      })
  })
}

export const trackPageVisit = async (page: string, course) => {
  try {
    const response = await CClient('/api/v1/track-page-visit/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ page, extraData: { course } }),
    })
    return response.ok
  } catch (error) {
    const { captureException } = await import('@sentry/node')
    captureException(error)
    return false
  }
}

export const staticFile = (path: string) => {
  if (!path.startsWith('/')) path = '/' + path
  return (process.env.NEXT_PUBLIC_STATIC_URL ?? '') + path
}

export const getLinkWithNextParam = (link: string, next: string) => {
  if (next) return `${link}?next=${next}`
  return link
}

export const getPriceWithCommas = (x: number) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

export const getPaymentPageLinkWithParam = (
  paymentPageLink: string,
  campaign: string,
  name: string,
  email: string,
  phone: string
) =>
  `${paymentPageLink}?campaign=${campaign}&child_name=${name}&email=${email}&phone=${phone}`

export const scrollToTop = () => {
  window.scrollTo({ top: 0, behavior: 'smooth' })
}

export const copyToClipboard = (contentToCopy: string) => {
  navigator.clipboard.writeText(contentToCopy)
}

export const handleByteConversion = (
  fileSizeInBytes: number,
  decimalPlaces: number
) => {
  if (fileSizeInBytes >= FILE_SIZE_UNITS_IN_BYTES.GB)
    return `${(fileSizeInBytes / FILE_SIZE_UNITS_IN_BYTES.GB).toFixed(
      decimalPlaces
    )} GB`

  if (fileSizeInBytes >= FILE_SIZE_UNITS_IN_BYTES.MB)
    return `${(fileSizeInBytes / FILE_SIZE_UNITS_IN_BYTES.MB).toFixed(
      decimalPlaces
    )} MB`

  if (fileSizeInBytes >= FILE_SIZE_UNITS_IN_BYTES.KB)
    return `${(fileSizeInBytes / FILE_SIZE_UNITS_IN_BYTES.KB).toFixed(
      decimalPlaces
    )} KB`

  return `${fileSizeInBytes.toFixed(decimalPlaces)} B`
}

export const getParsedTime = (timeInSeconds: number) => {
  const days = Math.floor(timeInSeconds / (3600 * 24))
  const hours = Math.floor((timeInSeconds % (3600 * 24)) / 3600)
  const minutes = Math.floor((timeInSeconds % 3600) / 60)
  const seconds = Math.floor(timeInSeconds % 60)

  const dayString = days > 0 ? days + ' d' : ''
  let hourString = hours > 0 ? hours + (hours == 1 ? ' hr' : ' hrs') : ''
  let minuteString =
    minutes > 0 ? minutes + (minutes == 1 ? ' min' : ' mins') : ''
  let secondString =
    seconds > 0 ? seconds + (seconds == 1 ? ' sec' : ' secs') : ''

  const addLeadingComma = (timeString: string) => ', ' + timeString

  if (hourString && dayString) hourString = addLeadingComma(hourString)
  if (minuteString && (dayString || hourString))
    minuteString = addLeadingComma(minuteString)
  if (secondString && (dayString || hourString || minuteString))
    secondString = addLeadingComma(secondString)

  return dayString + hourString + minuteString + secondString
}

export const handleRedirectionWithSignupSource = (
  signupSource: string,
  redirectionURL: string
) => {
  setToLocalStorage(SIGNUP_SOURCE_LOCAL_STORAGE_KEY, signupSource, () => {
    import('@sentry/node').then((s) =>
      s.captureMessage(`Unable to store signup source ${signupSource}`)
    )
  })
  Router.push(
    getUrlWithQueryString(redirectionURL, Router.asPath.split('?')[1])
  )
}

export const removePhoneParam = (url: string) => {
  const baseUrl = url.split('?')[0]
  const paramStr = url.split('?')?.[1]
  if (!paramStr) return baseUrl
  const params = new URLSearchParams(`?${paramStr}`)
  params.has('phone') && params.delete('phone')
  return baseUrl + '?' + params.toString()
}

export const getUrlWithQueryString = (url: string, queryString: string) => {
  // Handling the case where url passed may have query parameters
  const [urlWithoutQueryString, urlQueryString] = url.split('?')
  const finalQueryString = `${queryString || ''}${
    queryString && urlQueryString ? '&' : ''
  }${urlQueryString || ''}`

  const finalURL =
    urlWithoutQueryString + (finalQueryString ? `?${finalQueryString}` : '')
  return finalURL
}

export const queryStringToObject = (queryString?: string) => {
  if (!queryString) return null
  const params = new URLSearchParams(queryString)
  return Array.from(params.entries()).reduce((prev, [key, value]) => {
    return {
      ...prev,
      [key]: value,
    }
  }, {})
}

/**
 *
 * @param object The base object eg {xyz:123}
 * @param paths An array of paths to omit. [xyz]
 */
export const omit = (object: Record<string, Primitive>, paths: string[]) => {
  if (!object || !paths.length) return null

  const newObject = { ...object }
  for (const path of paths) {
    delete newObject[path]
  }
  return newObject
}

/**
 * Check if a route path is available in the provided route array.
 * @param route | string route
 * @param routeEndPoints | string route endpoints to test against
 */
export const hasRoute = (route: string, routeEndPoints: string[]) => {
  return routeEndPoints.find((element) => minimatch(route, element))
}

/**
 * Check if the provided numbers are Indian numbers
 * @param {string} numbers | array of numbers
 */
export const areIndianNumbers = (numbers: string[]) => {
  return numbers?.every((number) => number?.startsWith('+91'))
}

/**
 * This is the javascript alternative of unix sleep function
 * use this only on development not in prod unless required
 * @param {number} ms | time to wait in miliseconds
 */
export const sleep = (ms: number): Promise<void> => {
  return new Promise((resolve) => setTimeout(resolve, ms))
}

export const getIsBirthday = (dob, timezone) => {
  const currentDate = new Date().toLocaleDateString('en-US', {
    timeZone: timezone,
  })
  const [currentMonth, currentDay] = currentDate.split('/')

  const birthDate = new Date(dob)
  const birthMonth = birthDate.getMonth() + 1
  const birthday = birthDate.getDate()

  if (
    currentMonth == birthMonth.toString() &&
    currentDay == birthday.toString()
  )
    return true

  return false
}

export const updateUserSchool = async (
  userId: number,
  schoolId,
  schoolName
) => {
  try {
    // if school id is null, create a new school then update the user school
    const updatedSchoolId = !schoolId
      ? await addNewSchoolToDatabase(schoolName)
      : schoolId
    const requestUrl = '/api/v1/user-schools/'
    const requestBody = JSON.stringify({ schoolId: updatedSchoolId, userId })
    const response = await CClient(requestUrl, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: requestBody,
    })
    if (!response.ok)
      throw new Error(
        await getAPIErrorString(response, requestUrl, requestBody)
      )
  } catch (error) {
    captureException(error)
  }
}

export const addNewSchoolToDatabase = async (schoolName: string) => {
  const requestUrl = '/api/v1/schools/'
  const requestBody = JSON.stringify({ title: schoolName })
  const response = await CClient(requestUrl, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: requestBody,
  })
  if (!response.ok)
    throw new Error(await getAPIErrorString(response, requestUrl, requestBody))

  const { id } = await response.json()
  return id
}

export function toTitleCase(str: string) {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
  })
}

export function createReverseMapping(obj: Record<string, string | string[]>) {
  const reverseMapping = new Map()

  for (const [key, value] of Object.entries(obj)) {
    if (Array.isArray(value)) {
      for (const v of value) {
        reverseMapping.set(v, key)
      }
    } else {
      reverseMapping.set(value, key)
    }
  }

  return reverseMapping
}

import { defineMessage } from '@lingui/macro'

enum LogoThemeEnum {
  default = 'main',
  republic = 'republic',
  diwali = 'diwali',
  christmas = 'christmas',
}

//logo theme
export const LOGO_THEME = LogoThemeEnum.default

//s3 config for uploading files
export const AWS_ACCESS_KEY_ID = 'AKIA3JT624LWHFW3RQH3'
export const AWS_ENDPOINT = 'https://codingal.s3.amazonaws.com'

export const NAVBAR_LINKS = {
  HOME: {
    text: defineMessage({ message: `Home` }),
    link: '/',
  },
  LOGIN: {
    text: defineMessage({ message: `Login` }),
    link: '/login/',
  },
  REGISTER: {
    text: defineMessage({ message: `Sign Up` }),
    link: '/register/',
  },
  COMPETITIONS: {
    text: defineMessage({ message: `Competitions` }),
    link: '/competitions/',
  },
  QUIZZES: {
    text: defineMessage({ message: `Quizzes` }),
    link: '/quizzes/',
  },
  BOOK_TRIAL_CLASS: {
    text: defineMessage({ message: `Try a free class` }),
    link: '/book-trial-class/',
  },
  BOOK_FREE_CLASS: {
    text: defineMessage({ message: `Try a free class` }),
    link: '/register/',
  },
  DASHBOARD: {
    text: defineMessage({ message: `Dashboard` }),
    link: '/dashboard/',
  },
  JOIN_CLASS_LOGGED_OUT: {
    text: defineMessage({ message: `Join class` }),
    link: '/login/',
    next: '/trial-class/join/',
  },
  JOIN_CLASS_LOGGED_IN: {
    text: defineMessage({ message: `Join class` }),
    link: '/trial-class/join/',
  },
  CURRICULUM: {
    text: defineMessage({ message: `Curriculum` }),
    link: '/curriculum/',
  },
  CODING_FOR_KIDS: {
    text: defineMessage({ message: `Coding for Kids` }),
    link: '/coding-for-kids/',
  },
  CODING_PROJECTS: {
    text: defineMessage({ message: `Coding projects` }),
    link: '/coding-projects/iit-bombay-mood-indigo-coding-project/',
  },
  REFERRAL: {
    text: defineMessage({ message: `Refer & earn` }),
    link: '/referral/',
  },
  WHY_CODINGAL: {
    text: defineMessage({ message: `Why Codingal` }),
    link: '/why-codingal/',
  },
  RESOURCES: {
    text: defineMessage({ message: `Resources` }),
    link: '',
  },
  COURSES: {
    text: defineMessage({ message: `Courses` }),
    link: '/courses',
  },
}

export const MOBILE_MENU_LOGGED_OUT = [
  NAVBAR_LINKS.HOME,
  NAVBAR_LINKS.QUIZZES,
  NAVBAR_LINKS.COMPETITIONS,
  NAVBAR_LINKS.COURSES,
]

export const TEACHER_JOB_PORTAL_LINK =
  'https://codingal.freshteam.com/jobs/--hp7mLPXO3v/coding-teacher-remote'

export const DATE_TIME_FORMAT = 'hh:mm A [Z], ddd MMM DD'

export const CELERY_TASK_STATUS = {
  FAILED: ['FAILURE', 'REVOKED', 'RETRY'],
  PENDING: ['PENDING', 'STARTED', 'RECEIVED'],
  SUCCESS: ['SUCCESS'],
}

export const SUPPORT_WHATSAPP_NUMBER = {
  courseClass: { label: '+1 (510) 361-9534', value: '+15103619534' },
  trialClass: { label: '+916361158952', value: '+916361158952' },
}

export const FILE_SIZE_UNITS_IN_BYTES = {
  KB: 10 ** 3,
  MB: 10 ** 6,
  GB: 10 ** 9,
}

export const DEFAULT_RELATIONSHIP_MANAGER_PHONE_NUMBER = '+1 (510) 361-9534'

export const USER_COUNTRY_FROM_IP_COOKIE = 'user_country'

export const MASKED_EMAIL = 'maskedEmail'

export const CAREERS_LINK = '/careers/'

export const WEEK_DAYS = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']

export const MAXMIND_GEOIP2_DB_FILE_NAME = 'GeoIP2-Country.mmdb'

export const MAXMIND_GEOIP2_DB_FALLBACK_FILE_NAME =
  'Fallback-GeoIP2-Country.mmdb'

export const REFERRAL_POPUP_DURATION = 30

export const TRIAL_CLASS_PERSONA_SURVEY_LOCALSTORAGE_KEY = 'persona-survey'

import { useSessionContext } from '@contexts/session'
import { USER_COUNTRY_FROM_IP_COOKIE } from '@lib/constants/common'
import {
  getCountryForTimezone,
  getCountryFromCountryName,
  getCountryFromLocale,
} from '@lib/utils/country-detect'
import { SYSTEM_TIMEZONE } from '@lib/utils/date-native'
import countryToCurrency from 'country-to-currency'
import { useRouter } from 'next/router'
import { parseCookies } from 'nookies'
import { useEffect, useState } from 'react'

type Props = {
  isLowerCase?: boolean
  isDeterminedViaSystemTimezoneOnly?: boolean
}

const useDetectCountry = ({
  isLowerCase = false,
  isDeterminedViaSystemTimezoneOnly = false,
}: Props = {}) => {
  const {
    country: countryFromContext,
    timezone: timezoneFromContext,
  } = useSessionContext()

  const router = useRouter()
  const locale = router?.locale
  const isDefaultLocale = locale === router?.defaultLocale
  const countryCodeViaCookie = parseCookies()?.[USER_COUNTRY_FROM_IP_COOKIE]

  const determineCountry = async () => {
    if (isDeterminedViaSystemTimezoneOnly) {
      const currentCountry = await getCountryForTimezone(SYSTEM_TIMEZONE)
      return currentCountry
    }

    if (locale && !isDefaultLocale) {
      const countryViaLocale = await getCountryFromLocale(locale)
      if (countryViaLocale) return countryViaLocale
    }

    if (countryFromContext) {
      const countryViaCtxCountry = await getCountryFromCountryName(
        countryFromContext
      )
      if (countryViaCtxCountry) return countryViaCtxCountry
    }

    if (countryCodeViaCookie) {
      const { getCountry } = await import('countries-and-timezones')
      const countryViaCookie = getCountry(countryCodeViaCookie)
      if (countryViaCookie) return countryViaCookie
    }

    if (timezoneFromContext) {
      const countryViaCtxTimezone = await getCountryForTimezone(
        timezoneFromContext
      )
      if (countryViaCtxTimezone) return countryViaCtxTimezone
    }

    const currentCountry = await getCountryForTimezone(SYSTEM_TIMEZONE)
    return currentCountry
  }

  const [country, setCountry] = useState({
    id: '',
    name: '',
    timezones: [],
  })

  const getCountry = async () => {
    const currentCountry = await determineCountry()
    setCountry(currentCountry)
  }

  useEffect(() => {
    getCountry()
  }, [])

  const defaultCountry = {
    countryCode: '',
    countryName: '',
    currency: '',
  }
  return country
    ? {
        countryCode: isLowerCase ? country.id.toLocaleLowerCase() : country.id,
        countryName: country.name,
        currency: countryToCurrency[country.id],
      }
    : defaultCountry
}

export default useDetectCountry

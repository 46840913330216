declare global {
  interface Window {
    _hsq: any
  }
}

export const trackHubSpotPageview = (url: string) => {
  const _hsq = (window._hsq = window._hsq || [])
  _hsq.push(['setPath', url])
  _hsq.push(['trackPageView'])
}
